/* jslint browser: true */
/* global window, Stripe */

export default function () {
  const stripe = Stripe(window.stripeKey, {
    betas: ['checkout_beta_4'],
  });
  const { successUrl, cancelUrl } = window;

  const displayError = document.getElementById('error-message');
  const checkoutButtons = document.getElementsByClassName('js-checkout-button');

  [].forEach.call(checkoutButtons, (el) => {
    el.addEventListener('click', (evt) => {
      evt.preventDefault();
      if (!el.dataset || !el.dataset.sku) {
        return;
      }
      const { dataset: { sku } } = el;

      stripe.redirectToCheckout({
        items: [{ sku, quantity: 1 }],
        successUrl,
        cancelUrl,
      })
        .then((result) => {
          if (result.error) {
            displayError.textContent = result.error.message;
          }
        });
    });
  });
}
