import { addClass, removeClass } from './utils';

export default function () {
  const $teamCheckbox = document.getElementById('entry_is_relay_team');
  const $teamHiddenFields = document.getElementsByClassName('js-show-for-relay-team');
  const $teamNameField = document.getElementById('entry_team_name');

  function hide() {
    addClass($teamHiddenFields[0], 'is-hidden');
    $teamNameField.required = false;
  }
  function show() {
    removeClass($teamHiddenFields[0], 'is-hidden');
    $teamNameField.required = true;
  }
  function checkState() {
    if ($teamCheckbox.checked) {
      show();
    } else {
      hide();
    }
  }

  $teamCheckbox.addEventListener('change', checkState);
  checkState();
}
