export function hasClass(el, className) {
  return el.classList
    ? el.classList.contains(className)
    : new RegExp(`\\b${className}\\b`).test(el.className);
}

export function addClass(el, className) {
  if (el.classList) el.classList.add(className);
  else if (!hasClass(el, className)) el.className += ` ${className}`;
}

export function removeClass(el, className) {
  if (el.classList) el.classList.remove(className);
  else {
    el.className = el.className.replace(
      new RegExp(`\\b${className}\\b`, 'g'),
      '',
    );
  }
}

export function getPosition(element) {
  let xPosition = 0;
  let yPosition = 0;
  let el = element;

  while (el) {
    if (el.tagName === 'BODY') {
      // deal with browser quirks with body/window/document and page scroll
      const xScrollPos = el.scrollLeft || document.documentElement.scrollLeft;
      const yScrollPos = el.scrollTop || document.documentElement.scrollTop;

      xPosition += el.offsetLeft - xScrollPos + el.clientLeft;
      yPosition += el.offsetTop - yScrollPos + el.clientTop;
    } else {
      xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
      yPosition += el.offsetTop - el.scrollTop + el.clientTop;
    }

    el = el.offsetParent;
  }
  return {
    x: xPosition,
    y: yPosition,
  };
}

export function throttle(fn, wait) {
  let time = Date.now();

  return () => {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}

export function hideMe(selector) {
  const HIDDEN = 'is-hidden';
  const els = document.getElementsByClassName(selector);

  if (!els) {
    return;
  }

  [].forEach.call(els, (el) => {
    if (!el.dataset || !el.dataset.hideName) {
      return;
    }
    const button = document.getElementsByClassName(
      `js-toggle-hide-${el.dataset.hideName}`,
    );
    if (!button || !button[0]) {
      return;
    }

    addClass(el, HIDDEN);

    button[0].addEventListener(
      'click',
      (evt) => {
        evt.preventDefault();
        if (hasClass(el, HIDDEN)) {
          removeClass(el, HIDDEN);
          if (el.dataset.hasOwnProperty('hideButton')) {
            addClass(button[0], HIDDEN);
          }
        } else {
          addClass(el, HIDDEN);
        }
      },
      false,
    );
  });
}
