// @flow

import * as d3 from "d3";
import { geoPath, geoCentroid, geoTransform } from "d3-geo";
import L from "leaflet";

const tileLayer = L.tileLayer(
  "https://api.mapbox.com/styles/v1/mapbox/outdoors-v9/tiles/256/{z}/{x}/{y}?access_token={accessToken}",
  {
    attribution:
      '<a href="http://www.mapbox.com/about/maps/" target="_blank">Terms &amp; Feedback</a>',
    accessToken:
      "pk.eyJ1IjoiZXJya2tnZW9yZ2UiLCJhIjoiY2l5cjhmMmJoMDAxaDJ3cGQyZnhkZGo1aSJ9._2yUfXjYW2tzXIMcZTpNMQ",
  },
);

type MouseEventWithDataset = MouseEvent & {
  target: {
    dataset: {
      route: Object,
      zoom: Object,
    },
  },
};

const CENTRE = { lat: 51.63714, lng: -2.35313 };

export default class Map {
  data: Object;
  map: Object;
  path: Object;
  projection: Object;
  zoom: number;
  overlays: Object;

  constructor(selector: string = "js-route-map") {
    this.zoom = 14;
    const selection = document.getElementsByClassName(selector);
    const container = document.getElementsByClassName("js-map-container")[0];
    const legend = document.getElementsByClassName("js-key");
    this.overlays = {};

    for (var i = 0; i < selection.length; i++) {
      let domEl = selection.item(i);

      if (!domEl) {
        continue;
      }

      this.renderMap(domEl);

      // Add routes to the path
      this.renderOverlay("cycle");
      this.renderOverlay("run");
    }

    this.map.on("zoomend", () => {
      for (let key in this.overlays) {
        this._resetTransform(this.overlays[key]);
      }
    });

    for (let el of legend) {
      el.addEventListener("click", this.highlightClick, true);
    }
  }

  highlightClick = (evt: MouseEventWithDataset) => {
    evt.preventDefault();
    const {
      target: { dataset },
    } = evt;
    if (dataset) {
      const { route, zoom } = dataset;
      this.highlight(route, zoom);
    }
  };

  highlight(route: string, zoom: string) {
    if (!this.overlays.hasOwnProperty(route)) {
      return;
    }
    const zoomLevel = parseInt(zoom, 10);
    this.map.setView(this.overlays[route].centroid, zoomLevel);
  }

  /**
   * Instanciates a leavlet map, and creates an SVG overlay of the route
   * @param domeEl <Element>
   */
  renderMap(domEl: Object) {
    const scrollWheelZoom = false;

    this.map = L.map(domEl, { scrollWheelZoom }).addLayer(tileLayer);

    // Local closure access for projectPoint
    const map = this.map;
    this.map.setZoom(this.zoom);
    this.map.setView(new L.LatLng(CENTRE.lat, CENTRE.lng));

    function projectPoint(x, y) {
      var point = map.latLngToLayerPoint(new L.LatLng(y, x));
      this.stream.point(point.x, point.y);
    }

    this.projection = geoTransform({ point: projectPoint });
    this.path = geoPath().projection(this.projection);
  }

  renderOverlay(overlay: string) {
    d3.json(`${window.location.origin}/data/${overlay}.geojson`, (data) => {
      this.overlays[overlay] = this._renderPath(data, overlay);
      this.overlays[overlay].centroid = geoCentroid(data).reverse();
    });
  }

  /**
   * Calculate transform for the SVG element to align with the map
   */
  _resetTransform({ data, g, svg, route }) {
    const bounds = this.path.bounds(data);
    const topLeft = bounds[0];
    const bottomRight = bounds[1];

    svg
      .attr("width", bottomRight[0] - topLeft[0] + 50)
      .attr("height", bottomRight[1] - topLeft[1] + 50)
      .style("left", topLeft[0] - 25 + "px")
      .style("top", topLeft[1] - 25 + "px");

    // offset for the whole SVG overlay to line up with the map
    g.attr("transform", `translate(${-topLeft[0] + 25}, ${-topLeft[1] + 25})`);

    route.attr("d", this.path);
  }

  _renderPath(data, overlay: string): Object {
    const svg = d3.select(this.map.getPanes().overlayPane).append("svg");
    const g = svg.append("g").attr("class", "leaflet-zoom-hide");

    const route = g
      .append("g")
      .attr("class", `route route--${overlay}`)
      .selectAll("path")
      .data(data.features)
      .enter()
      .append("path");

    this._resetTransform({ data, g, svg, route });
    return { data, g, svg, route };
  }
}
