import initializr from 'initializr';
import Nav from './lib/nav';
import entryForm from './lib/entry-form';
import stripe from './lib/stripe';
import { hideMe } from './lib/utils';
import Map from './lib/map';
import initSubmitButton from './lib/contact-form';

const MIN_AGE = 16;

initializr({
  common: {
    initialize: () => {
      new Nav();
    },
  },
  welcome: {
    event: () => {
      new Map('js-map');
    },
  },
  entries: {
    new: () => {
      entryForm();
    },
    edit: () => {
      entryForm();
    },
    index: () => {
      stripe();
      hideMe('js-hideme');
    },
  },
  confirmations: {
    new: () => {
      hideMe('js-hideme');
    },
  },
  contact: {
    initialize: () => {
      initSubmitButton();
    },
  },
  registrations: {
    initialize: () => {
      $('.js-popup').on('click', (evt) => {
        evt.preventDefault();
        window.open(evt.target.href, '', 'width=600,height=500');
      });
      const now = new Date();
      const maxYear = now.getFullYear() - MIN_AGE;
      $('.js-datepicker').pickadate({
        format: 'dddd, dd mmm, yyyy',
        formatSubmit: 'yyyy-mm-dd',
        selectYears: 70,
        selectMonths: true,
        hiddenName: true,
        min: [1940, 0, 1],
        max: [maxYear, 0, 31],
      });
    },
    edit: () => {
      hideMe('js-hideme');
    },
  },
});
