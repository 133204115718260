import { hasClass, addClass, removeClass } from './utils';

export default class Nav {
  constructor() {
    this._onLoad();
  }

  static toggle() {
    const ACTIVE = 'nav-open';
    const TARGET = document.body;

    if (hasClass(TARGET, ACTIVE)) {
      removeClass(TARGET, ACTIVE);
    } else {
      addClass(TARGET, ACTIVE);
      document.scrollingElement.scrollTop = 0;
    }
  }

  _onLoad() {
    const $button = document.querySelector('.js-nav-toggle');
    if (!$button) {
      return;
    }

    $button.addEventListener('click', Nav.toggle, false);
  }
}
